import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import s from '../Popup.module.css'

export default function PopupRatingUsers(props) {
    return (
        <div>
            <Popup trigger=
                {<button> {props.nameBtnPopup} </button>}
                modal nested>
                {
                    close => (
                        <div>
                            <div class={s.closeBtnPopup}>
                                <button onClick=
                                    {() => close()}>
                                    ✖
                                </button>
                            </div>
                            <div className={s.modal}>
                                <div className={s.content}>
                                    <h2>Что такое уровень доверия?</h2>
                                    <p>
                                        Доверие - это основа любого начинания. Мы ценим любой ваш вклад с целью сделать ваше представительство ярким, интересным, незабываемым.
                                        Это всегда привлекает значительно больше потенциальных попутчиков. По этому за ваше старание сделать что-то интересное мы начисляем очки кармы за каждое полезное действие.
                                        Их количество определяет уровень доверия к вашему аккаунту.
                                        Уровни доверия начинаются с Начального (не заполненный аккаунт, менее 300 кармы), потом идет Средний(до 1000) и Высокий.
                                    </p>
                                    <h3>За что начисляются баллы кармы:</h3>
                                    <p>
                                        В целях избежания злоупотреблений мы не раскрываем алгоритмы повышения уровня доверия. Для повышения уровня доверия начните с подробного заполнения своего профиля.
                                        Об изменении кармы и уровня доверия мы сообщим незамедлительно.
                                        Нример, 250 баллов вы можете набрать добавив первые 10 фотографий - это первый шаг создания качественного профиля.
                                    </p>
                                    <h3>Что дает повышение уровня доверия?</h3>
                                    <p>
                                        В первую очередь, другие пользователи видят ваш уровень доверия.
                                        Пользователи с Premium аккаунтами или с высоким уровнем доверия получают значительно больше контактов, по сравнению с остальными.
                                        Кроме этого, более высокий уровень доверия открывает дополнительные функции.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Popup>
        </div>
    )
};